/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Después del ", React.createElement(_components.a, {
    href: "http://cadenaser.com/ser/2018/11/06/tribunales/1541488791_237317.html?fbclid=IwAR2M5hK6pH_9xKyDDyK0lnBPULl6VbenMFZ52MiBTv_sSlHV62HI0uUkjAY"
  }, "dantesco espectáculo de esta semana de la justicia en relación a las hipotecas"), " queda patente que la banca tiene más poder sobre nuestra economía que el Estado español. Pero, ¿por qué? Porque puede crear dinero (prestando euros que no tiene, que es a lo que se dedica), mientras que España transfirió la capacidad de crear moneda al BCE. Por esto sufrimos una asimetría de poder que genera este tipo de situaciones. Visto lo visto empiezo a ver con buenos ojos la creación de un banco público para recuperar parte de la soberanía monetaria. Tenemos el ICO y parte de Bankia, ¿no es hora de que llegue a la familia el crédito?🤔"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://chartalismo.blogspot.com/2016/11/que-es-el-dinero-parte-ii.html"
  }, "Imagen"), " del imprescindible blog ", React.createElement(_components.a, {
    href: "http://chartalismo.blogspot.com"
  }, "Desempleo Cero"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
